import * as React from "react"
import Page from "../components/page.js"
import {Link} from "gatsby"

import '../components/css/pricing.scss'

const Pricing = () => {
  return (
    <>
      <Page headerLogoColor='#ffffff' headerColor='#ffffff'>
        <div className="pricing-container">
          <div className="pricing-content-wrapper content-wrapper">
            <div className="pricing-content">
              <h2>Pricing</h2>
              <h4>Pricing that scales for all types of grows.</h4>
              <div className="pricing-types">
                <div className="dynamic pricing-type">
                  <div className="type">
                    <h5>Dynamic</h5>
                    <h6>Ideal for small-scale operations with simple pay-as-you-need pricing.</h6>
                  </div>
                  <div className="perks">
                    <ul>
                      <li>Easy to setup and fully integrated ecosystem.</li>
                      <li>Access to InstaLeaf Cloud and MasterGrower AI.</li>
                      <li>Self serve products and services. Use what best fits your grow.</li>
                    </ul>
                  </div>
                  <div className="action">
                    <Link to="/contact">
                      <h5>Get Started</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="enterprise pricing-type">
                  <div className="type">
                    <h5>Enterprise</h5>
                    <h6>Ideal for large-scale agriculture operations that require customized support.</h6>
                  </div>
                  <div className="perks">
                    <table>
                      <tr>
                        <td>Volume-based Rates</td>
                        <td>Dedicated customer support</td>
                      </tr>
                      <tr>
                        <td>Custom technology offerings</td>
                        <td>Enterprise-grade quality and SLAs</td>
                      </tr>
                    </table>
                  </div>
                  <div className="action">
                    <Link to="/contact">
                      <h5>Contact Sales</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  )
}

export default Pricing
